@import url('https://cloud.typography.com/6718276/7638612/css/fonts.css');

body {
  margin: 0;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.app {
  min-height: 100vh;

  background-size: cover;
  background-position: center;
}
